import { Container, Divider } from "@mantine/core";
import timelineClasses from "./TimelineItem.module.css";

interface SeparatorProps {
    callDuration?: string;
    timestamp?: string;
}

function determineLabel(callDuration?: string, timestamp?: string) {
    if (callDuration && timestamp) {
        return `Call ended - ${timestamp} | Duration ${callDuration}`;
    } else if (timestamp) {
        return `Call ended - ${timestamp}`;
    } else if (callDuration) {
        return `Call ended - Duration ${callDuration}`;
    }
    return "Call ended";
}
export function Separator({ callDuration, timestamp }: SeparatorProps) {
    const separatorLabel = determineLabel(callDuration, timestamp);

    return (
        <Container w="75%">
            <Divider
                classNames={{
                    root: timelineClasses.divider,
                    label: timelineClasses.label,
                }}
                label={separatorLabel}
                labelPosition="center"
                mt="6rem"
                mb="6rem"
            />
        </Container>
    );
}
