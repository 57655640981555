import { Flex, Space, Text, type TextProps } from "@mantine/core";
import { MarkdownViewer } from "@expert/solve-tools";
import { NotesStarIcon } from "../../../assets/NotesStarIcon";
import type { SessionSummaryMetadata } from "../../../shared-types";
import classes from "./SessionSummary.module.css";

const headers = ["Call reason", "Action taken", "Call conclusion", "Follow ups", "Additional info"];
function HeaderText(props: TextProps) {
    return <Text size="sm" fw={700} {...props} pt="sm" />;
}

export function generateSessionSummaryItem(metaData: SessionSummaryMetadata) {
    const headerRegex = new RegExp(headers.join("|"), "gi");
    const summaryWithMarkdown = metaData.summary.replace(headerRegex, "#### $&");
    return (
        <Flex direction="column" miw="10rem">
            <MarkdownViewer content={summaryWithMarkdown} options={{ overrides: { h4: HeaderText } }} />
        </Flex>
    );
}

export function SessionSummaryHeader() {
    return (
        <Flex direction="row" className={classes.timelineItemSessionSummaryHeader}>
            <NotesStarIcon />
            <Space pr="8px" />
            <Text className={classes.sessionSummaryHeader}>Call summary</Text>
        </Flex>
    );
}
